import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

type PaginationType = {
  currentPage: number
  totalPageNumber: number
  path: string
}

const Pagination = ({ currentPage, totalPageNumber, path }: PaginationType) => {
  // remove number on first page
  const befPage = currentPage - 1
  const befMark =
    currentPage === 1 ? (
      <li className="uk-disabled">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <span uk-pagination-previous=""></span>
        </a>
      </li>
    ) : (
      <li>
        <Link to={`${path}${befPage === 1 ? `` : befPage}`}>
          <span uk-pagination-previous=""></span>
        </Link>
      </li>
    )
  const nextMark =
    currentPage === totalPageNumber ? (
      <li className="uk-disabled">
        <a href="" target="_blank" rel="noopener noreferrer">
          <span uk-pagination-next=""></span>
        </a>
      </li>
    ) : (
      <li>
        <Link to={`${path}${currentPage + 1}`}>
          <span uk-pagination-next=""></span>
        </Link>
      </li>
    )

  return (
    <ul className="uk-pagination uk-flex-right uk-margin-medium-top" uk-margin="">
      {befMark}
      {Array.from({ length: totalPageNumber })
        .map((_, ind) => ind + 1)
        .map((pageNum) => {
          return pageNum === currentPage ? (
            <li key={`pageNum-${pageNum}`} className="uk-active">
              <div>
                <span>{pageNum}</span>
              </div>
            </li>
          ) : (
            <li key={`pageNum-${pageNum}`}>
              <Link to={`${path}${pageNum === 1 ? `` : pageNum}`}>{pageNum}</Link>
            </li>
          )
        })}
      {nextMark}
    </ul>
  )
}

export const pageQuery = graphql`
  query NewsQuery($skip: Int!, $limit: Int!, $language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products", "subscribe"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allNews(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          content
          title
          fields {
            slug
          }
          remoteImage {
            childImageSharp {
              gatsbyImageData(quality: 70)
            }
          }
          languages {
            locale
            column_name
            value
            desc
          }
          updated_at(fromNow: true, locale: $language)
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`

interface NewsPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        siteUrl: string
      }
    }
    allNews: {
      edges: [
        {
          node: {
            id: string
            title: string
            fields: {
              slug: string
            }
            remoteImage: IGatsbyImageData
            languages: [
              {
                column_name: string
                locale: string
                value: string
                desc: string
              }
            ]
            updated_at: string
            content: string
          }
        }
      ]
    }
  }
  pageContext: {
    currentPage: number
    numPages: number
    language: string
    type: string
  }
}

const NewsPage = (props: NewsPageProps) => {
  const { t } = useTranslation()
  const { edges } = props.data.allNews
  const { currentPage, numPages, language, type } = props.pageContext
  const { origin } = props.location

  return (
    <>
      <Hero type="investor">
        <h1 className="uk-heading-small">News</h1>
        <p>Redefining your customer experiences with Jasnita CX solutions.</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <div className="uk-width-expand@m">
              {edges.map((post) => {
                const postImg = getImage(post.node.remoteImage)

                return (
                  <section key={post.node.id} className="uk-section-small">
                    <h2>
                      <Link to={post.node.fields.slug}>
                        {post.node.languages.length > 0
                          ? post.node.languages.map((translation) => {
                              if (translation.locale === language && translation.column_name === `title`) {
                                {
                                  return translation.value
                                }
                              }
                            })
                          : post.node.title}
                      </Link>
                    </h2>
                    <div className="uk-child-width-expand@m" uk-grid="">
                      {post.node.remoteImage && postImg && (
                        <GatsbyImage
                          className="uk-width-1-1 uk-width-1-3@m uk-first-column"
                          style={{ borderRadius: `10px` }}
                          imgStyle={{ left: `auto`, right: `auto`, borderRadius: `10px` }}
                          image={postImg}
                          alt={post.node.title}
                        />
                      )}
                      <div>
                        <p>
                          {post.node.languages.length > 0
                            ? post.node.languages.map((translation) => {
                                if (translation.locale === language && translation.column_name === `content`) {
                                  {
                                    return translation.desc.replace(/^(.{200}[^\s]*).*/, `$1`)
                                  }
                                }
                              })
                            : post.node.content.replace(/^(.{200}[^\s]*).*/, `$1`)}
                          ...
                        </p>
                        <p className="uk-article-meta">
                          {t('Written on')} {post.node.updated_at}.
                        </p>
                      </div>
                    </div>
                  </section>
                )
              })}

              <Pagination currentPage={currentPage} totalPageNumber={numPages} path={`/${type}/`} />
            </div>

            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1">
                <div>
                  <form>
                    <h4>{t('Newsletter')}</h4>
                    <fieldset className="uk-fieldset">
                      <p>{t('Subscribe to receive our updates')}</p>
                      <div className="uk-margin">
                        <input className="uk-input" type="email" placeholder={t('Enter your email')} />
                      </div>
                      <div className="uk-margin">
                        <button className="uk-button uk-button-primary">{t('Subscribe')}</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewsPage
